import React, { useState, useEffect } from "react";
import "../Css/PopUpForm.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopUpForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const closeForm = () => setIsOpen(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a",
          subject: "New Contact Form Submission",
          from_name: "SAP Training tech",
          name: data.name,
          phone: data.phone,
          course: data.course,
          recipient_email: "shivanihiware77@gmail.com",
        }),
      });

      const result = await response.json();

      if (result.success) {
        toast.success("Form submitted successfully!");
        reset();
        closeForm();
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {isOpen && (
        <div className="popup-form-overlay">
          <div className="popup-form-container">
            <div className="popup-form-left">
              <h3 className="popup-form-title">
                Up-Skill your Career with the world's
              </h3>
              <p className="popup-form-highlight">
                Effective Online & Classroom Training
              </p>
              <p className="popup-form-stats">
                Trusted by <span className="highlight">3,50,850+</span> Satisfied Learners &{" "}
                <span className="highlight">123+</span> Corporates
              </p>
              <div className="popup-form-image-container"></div>
            </div>

            <div className="popup-form-right">
              <div className="popup-form-header">
                <h2 className="popup-form-header-title">Contact Us</h2>
                <button onClick={closeForm} className="popup-close-btn">
                  ✖
                </button>
              </div>

              <form className="popup-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="popup-form-input-group">
                  <input
                    type="text"
                    placeholder="Your Name"
                    {...register("name", {
                      required: "Name is required",
                    })}
                    className="popup-form-input"
                  />
                  {errors.name && <p className="popup-form-error">{errors.name.message}</p>}
                </div>

                <div className="popup-form-input-group">
                  <input
                    type="tel"
                    placeholder="Your Phone Number"
                    {...register("phone", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Enter a valid 10-digit number",
                      },
                    })}
                    className="popup-form-input"
                  />
                  {errors.phone && <p className="popup-form-error">{errors.phone.message}</p>}
                </div>

                <div className="popup-form-input-group">
                  <select
                    {...register("course", {
                      required: "Please select a course",
                    })}
                    className="popup-form-select"
                  >
                    <option value="">-- Select a Course --</option>
                    <option value="SAP">SAP</option>
                    <option value="SAP MM">SAP MM</option>
                    <option value="SAP FICO">SAP FICO</option>
                    <option value="SAP SD">SAP SD</option>
                    <option value="SAP HCM">SAP HCM</option>
                    <option value="SAP ABAP">SAP ABAP</option>
                    <option value="SAP BASIS">SAP BASIS</option>
                    <option value="SAP SCM">SAP SCM</option>
                    <option value="SAP ARIBA">SAP ARIBA</option>
                    <option value="SAP PP">SAP PP</option>
                    <option value="SAP PM">SAP PM</option>
                    <option value="SAP QM">SAP QM</option>
                    <option value="SAP LE&SL">SAP LE&SL</option>
                    <option value="SAP WM&EWM">SAP WM&EWM</option>
                    <option value="SAP FIORI">SAP FIORI</option>

                  </select>
                  {errors.course && <p className="popup-form-error">{errors.course.message}</p>}
                </div>

                <button type="submit" className="popup-form-submit" disabled={isLoading}>
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUpForm;
