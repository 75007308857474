import { useModal } from "../context/ModelContext";
import '../Css/GlobalModal.css'

const GlobalModal = () => {
    const { isModalOpen, closeModal, modalContent } = useModal();
    if (!isModalOpen) return null;

    return (
        <div>
            <div className="global-modal-overlay">
                <div className="global-modal-container">
                    <button
                        onClick={closeModal}
                        className="global-modal-close"
                    >
                        ✖
                    </button>
                    <div className="global-modal-content">{modalContent}</div>
                </div>
            </div>
        </div>
    )
}

export default GlobalModal;